import { authRoles } from './auth/authRoles'

export const navigations = [
    {
        name: 'Dashboard',
        path: '/dashboard/default',
        icon: 'dashboard',
    },
    // {
    //     name: 'Alternative',
    //     path: '/dashboard/alternative',
    //     icon: 'analytics',
    // },
    // {
    //     name: 'Inventory Management',
    //     path: '/dashboard/inventory-management',
    //     icon: 'store',
    // },
    // {
    //     label: 'Pages',
    //     type: 'label',
    // },
    {
        name: 'Home',
        icon: 'folder',
        children: [
            {
                name: 'Upload Slider',
                path: '/pages/slider',
                iconText: 'VO',
            },
        ],
    },
    {
        name: 'Employee',
        icon: 'folder',
        children: [
            {
                name: 'Create Employee',
                path: '/pages/employee',
                iconText: 'VO',
            },
            {
                name: 'All Employee',
                path: '/pages/employee-list',
                iconText: 'VO',
            },
        ],
    },
    // {
    //     name: 'Associate Customer',
    //     icon: 'folder',
    //     children: [
    //         {
    //             name: 'Add Association',
    //             path: '/pages/customer',
    //             iconText: 'VO',
    //         },
    //     ],
    // },
    {
        name: 'About',
        icon: 'folder',
        children: [
            {
                name: 'Our Story',
                path: '/pages/about/our-story',
                iconText: 'VO',
            },
            {
                name: 'Vision & Mission',
                path: '/pages/about/vision-mission',
                iconText: 'VO',
            },
            // {
            //     name: 'Board of Directors',
            //     path: '/pages/about/board-of-directors',
            //     iconText: 'VO',
            // },
            // {
            //     name: 'Management Team',
            //     path: '/pages/about/management-team',
            //     iconText: 'VO',
            // },
        ],
    }, 
    {
        name: 'Projects',
        icon: 'folder',
        children: [
            // {
            //     name: 'Land',
            //     icon: 'folder_icon',
            //     children: [
            //         {
            //             name: 'All  Project',
            //             path: '/pages/projects/land/all',
            //             iconText: 'NP',
            //         },
            //         {
            //             name: 'New Project',
            //             path: '/pages/projects/land/create-project',
            //             iconText: 'NP',
            //         },
                  
            //     ]
            // },
            // {
            //     name: 'Apartment',
            //     icon: 'folder_icon',
            //     children: [
                    {
                        name: 'All  Project',
                        path: '/pages/projects/all',
                        iconText: 'NP',
                    },
                    {
                        name: 'New Project',
                        path:  '/pages/projects/create-project',
                        iconText: 'NP',
                    },
            //     ]
            // },
            // {
            //     name: 'Completed',
            //     icon: 'folder_icon',
            //     children: [
            //         {
            //             name: 'All Project',
            //             path: '/pages/projects/project-list/completed',
            //             iconText: 'PL',
            //         },
            //         {
            //             name: 'New Project',
            //             path: '/pages/projects/completed/create-project',
            //             iconText: 'NP',
            //         },
            //     ]
            // },
        ],
    },

    // {
    //     name: 'Career',
    //     icon: 'folder',
    //     children: [
    //         {
    //             name: 'All Career',
    //             path: '/pages/all-career',
    //             iconText: 'VO',
    //         },
    //     ],
    // },
    {
        name: 'Articles',
        icon: 'folder',
        children: [
            {
                name: 'Add Articles',
                path: '/pages/articles/create',
                iconText: 'VO',
            },
            {
                name: 'View All Articles',
                path: '/pages/articles/view',
                iconText: 'VO',
            },
        ],
    },
    // {
    //     name: 'Vendors',
    //     icon: 'people',
    //     children: [
    //         {
    //             name: 'Vendor List',
    //             path: '/pages/vendor-list',
    //             iconText: 'CL',
    //         },
    //         {
    //             name: 'View Vendor',
    //             path: '/pages/view-vendor',
    //             iconText: 'VC',
    //         },
    //         {
    //             name: 'New Vendor',
    //             path: '/pages/new-vendor',
    //             iconText: 'NC',
    //         },
    //     ],
    // },
    // {
    //     name: 'Customers',
    //     icon: 'people',
    //     children: [
    //         {
    //             name: 'Customer List',
    //             path: '/pages/customer-list',
    //             iconText: 'CL',
    //         },
           
    //     ],
    // },
    {
        name: 'Contacts',
        icon: 'people',
        children: [
            {
                name: 'Contact List',
                path: '/pages/contact-list',
                iconText: 'CL',
            },
           
        ],
    },
    // {
    //     name: 'Categories',
    //     icon: 'shopping_cart',
    //     children: [
    //         {
    //             name: 'Category List',
    //             path: '/pages/category-list',
    //             iconText: 'PL',
    //         },
    //         {
    //             name: 'View Category',
    //             path: '/pages/view-category',
    //             iconText: 'VP',
    //         },
    //         {
    //             name: 'New Category',
    //             path: '/pages/new-category',
    //             iconText: 'NP',
    //         },
    //     ],
    // },
    // {
    //     name: 'Brands',
    //     icon: 'shopping_cart',
    //     children: [
    //         {
    //             name: 'Brand List',
    //             path: '/pages/brand-list',
    //             iconText: 'PL',
    //         },
    //         {
    //             name: 'View Brand',
    //             path: '/pages/view-brand',
    //             iconText: 'VP',
    //         },
    //         {
    //             name: 'New Brand',
    //             path: '/pages/new-brand',
    //             iconText: 'NP',
    //         },
    //     ],
    // },
    // {
    //     name: 'Attributes',
    //     icon: 'shopping_cart',
    //     children: [
    //         {
    //             name: 'Attribute List',
    //             path: '/pages/attribute-list',
    //             iconText: 'PL',
    //         },
    //         {
    //             name: 'View Attribute',
    //             path: '/pages/view-attribute',
    //             iconText: 'VP',
    //         },
    //         {
    //             name: 'New Attribute',
    //             path: '/pages/new-attribute',
    //             iconText: 'NP',
    //         },
    //     ],
    // },
    // {
    //     name: 'Orders',
    //     icon: 'folder',
    //     children: [
    //         {
    //             name: 'Order List',
    //             path: '/pages/order-list',
    //             iconText: 'OL',
    //         },
    //         {
    //             name: 'View Order',
    //             path: '/invoice/fdskfjdsuoiucrwevbgd',
    //             iconText: 'VO',
    //         },
    //     ],
    // },
    // {
    //     name: 'Help Center',
    //     icon: 'help',
    //     children: [
    //         {
    //             name: 'FAQ 1',
    //             path: '/pages/faq-1',
    //             iconText: 'F1',
    //         },
    //         {
    //             name: 'FAQ 2',
    //             path: '/pages/faq-2',
    //             iconText: 'F2',
    //         },
    //     ],
    // },
    // {
    //     name: 'Pricing',
    //     icon: 'money',

    //     children: [
    //         {
    //             name: 'Pricing 1',
    //             iconText: 'P1',
    //             path: '/others/pricing-1',
    //         },
    //         {
    //             name: 'Pricing 2',
    //             iconText: 'P2',
    //             path: '/others/pricing-2',
    //         },
    //         {
    //             name: 'Pricing 3',
    //             iconText: 'P3',
    //             path: '/others/pricing-3',
    //         },
    //         {
    //             name: 'Pricing 4',
    //             iconText: 'P4',
    //             path: '/others/pricing-4',
    //         },
    //     ],
    // },
    // {
    //     name: 'User List',
    //     icon: 'people',
    //     children: [
    //         {
    //             name: 'User List 1',
    //             path: '/pages/user-list-1',
    //             iconText: 'U1',
    //         },
    //         {
    //             name: 'User List 2',
    //             path: '/pages/user-list-2',
    //             iconText: 'U2',
    //         },
    //         {
    //             name: 'User List 3',
    //             path: '/pages/user-list-3',
    //             iconText: 'U3',
    //         },
    //         {
    //             name: 'User List 4',
    //             path: '/pages/user-list-4',
    //             iconText: 'U3',
    //         },
    //     ],
    // },
    
]
