import axios from 'axios'


const axiosInstance = axios.create ({
    baseURL : 'https://api.b2gsoft.com/api/v1',
    // baseURL : process.env.NODE_ENV === `development` ? `http://192.168.5.248:7777/api/v1` : `https://b2gsoft-api.lovesbd.com/api/v1`,
    headers: {
        'Accept': 'application/json'
    }
  })



// axiosInstance.interceptors.response.use(
//     (response) => response,
//     (error) => {
//         Promise.reject(
//             console.log('error=========',error?.response)
//             (error.response && error.response.data) || 'Something went wrong!'
//         )}
// )

export default axiosInstance
  