import React from 'react'

const pagesRoutes = [
    {
        path: '/pages/projects/land/create-project',
        component: React.lazy(() =>
            import('./projects/ProjectForm')
        ),
    },
    {
        path: '/pages/projects/create-project',
        component: React.lazy(() =>
            import('./projects/ProjectForm')
        ),
    },
    
    {
        path: '/pages/projects/edit-project',
        component: React.lazy(() =>
            import('./projects/ProjectForm')
        ),
    },
    {
        path: '/pages/projects/land/edit-project',
        component: React.lazy(() =>
            import('./projects/ProjectForm')
        ),
    },
    // {
    //     path: '/pages/projects/completed/create-project',
    //     component: React.lazy(() =>
    //         import('./projects/ProjectForm')
    //     ),
    // },
    {
        path: '/pages/projects/land/all',
        component: React.lazy(() =>
            import('./projects/ProjectList')
        ),
    },
    {
        path: '/pages/contact-list',
        component: React.lazy(() =>
            import('./contacts/ContactList')
        ),
    },
    {
        path: '/pages/projects/all',
        component: React.lazy(() =>
            import('./projects/ProjectList')
        ),
    },
    {
        path: '/pages/all-career',
        component: React.lazy(() => import('./career/allCareer')),
    },
    {
        path: '/pages/articles/create',
        component: React.lazy(() =>
            import('./articles/addArticles')
        ),
    },
    {
        path: '/pages/articles/edit',
        component: React.lazy(() =>
            import('./articles/addArticles')
        ),
    },


    {
        path: '/pages/articles/view',
        component: React.lazy(() =>
            import('./articles/allArticles')
        ),
    },
    {
        path: '/pages/user-list-1',
        component: React.lazy(() => import('./user-list/UserList1')),
    },
    {
        path: '/pages/user-list-2',
        component: React.lazy(() => import('./user-list/UserList2')),
    },
    {
        path: '/pages/user-list-3',
        component: React.lazy(() => import('./user-list/UserList3')),
    },
    {
        path: '/pages/user-list-4',
        component: React.lazy(() => import('./user-list/UserList4')),
    },
    {
        path: '/pages/faq-1',
        component: React.lazy(() => import('./faq/Faq1')),
    },
    {
        path: '/pages/faq-2',
        component: React.lazy(() => import('./faq/Faq2')),
    },
    {
        path: '/pages/vendor-list',
        component: React.lazy(() => import('./vendors/VendorList')),
    },
    {
        path: '/pages/new-vendor',
        component: React.lazy(() =>
            import('./vendors/vendor-form/VendorForm')
        ),
    },
    {
        path: '/pages/view-vendor',
        component: React.lazy(() =>
            import('./vendors/vendor-viewer/VendorViewer')
        ),
    },
    {
        path: '/pages/customer-list',
        component: React.lazy(() => import('./customers/CustomerList')),
    },
    {
        path: '/pages/new-customer',
        component: React.lazy(() =>
            import('./customers/customer-form/CustomerForm')
        ),
    },
    {
        path: '/pages/view-customer',
        component: React.lazy(() =>
            import('./customers/customer-viewer/CustomerViewer')
        ),
    },
    {
        path: '/pages/category-list',
        component: React.lazy(() => import('./categories/CategoryList')),
    },
    {
        path: '/pages/new-category',
        component: React.lazy(() => import('./categories/CategoryForm')),
    },
    {
        path: '/pages/view-category',
        component: React.lazy(() => import('./categories/CategoryViewer')),
    },
    {
        path: '/pages/brand-list',
        component: React.lazy(() => import('./brands/BrandList')),
    },
    {
        path: '/pages/new-brand',
        component: React.lazy(() => import('./brands/BrandForm')),
    },
    {
        path: '/pages/view-brand',
        component: React.lazy(() => import('./brands/BrandViewer')),
    },
    {
        path: '/pages/attribute-list',
        component: React.lazy(() => import('./attributes/AttributeList')),
    },
    {
        path: '/pages/new-attribute',
        component: React.lazy(() => import('./attributes/AttributeForm')),
    },
    {
        path: '/pages/view-attribute',
        component: React.lazy(() => import('./attributes/AttributeViewer')),
    },
    {
        path: '/pages/product-list',
        component: React.lazy(() => import('./products/ProductList')),
    },
    {
        path: '/pages/slider',
        component: React.lazy(() => import('./products/ProductForm')),
    },
    {
        path: '/pages/employee',
        component: React.lazy(() => import('./employee/EmployeeForm')),
    },
    {
        path: '/pages/employee-list',
        component: React.lazy(() => import('./employee/EmployeeList')),
    },
    {
        path: '/pages/about/our-story',
        component: React.lazy(() => import('./about/OurStoryForm')),
    },
    {
        path: '/pages/about/vision-mission',
        component: React.lazy(() => import('./about/VisionMission')),
    },
    {
        path: '/pages/about/board-of-directors',
        component: React.lazy(() => import('./about/BoardOfDirectors')),
    },
    {
        path: '/pages/about/management-team',
        component: React.lazy(() => import('./about/ManageMentTeam')),
    },
    {
        path: '/pages/customer',
        component: React.lazy(() => import('./customer/CustomerForm')),
    },
    {
        path: '/pages/edit-product',
        component: React.lazy(() => import('./products/ProductEditForm')),
    },
    {
        path: '/pages/view-product',
        component: React.lazy(() => import('./products/ProductViewer')),
    },
    {
        path: '/pages/order-list',
        component: React.lazy(() => import('./orders/OrderList')),
    },
]

export default pagesRoutes
